import React, { useState } from "react";
import { ContactFormWrapper } from "./Styled";
import { useForm } from "react-hook-form";
import { Box, LabelStyled } from "../../styled/Elements";
import { ContactButton } from "../../styled/CustomElements";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
  });
  const [phone, setPhone] = useState("");
  const handlePhoneChange = (e) => {
    // Allow only positive numbers
    const input = e.target.value;
    if (!isNaN(input) && input >= 0) {
      setPhone(input);
    }
  };
  const getApi = async (data) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        "https://superactivebaby.app4orce.com/api/public/api/contact-us",
        data
      );
      if (response.status === 200) {
        reset({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        toast.success(t("submission"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(t("submission_fail"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      toast.error(t("submission_fail"), {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      setIsLoading(false)
    }
  };
  const onSubmit = async (data) => {
    console.log(data);
    getApi(data);
  };

  return (
    <>
      <ContactFormWrapper lang={lang}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="field-box">
            <LabelStyled
              color="var(--white)"
              weight="lighter"
              family="var(--medium)"
            >
              {t("name")}
            </LabelStyled>
            <input
              className="input-field"
              placeholder={t("namePlaceholder")}
              {...register("name", { required: true })}
              aria-invalid={errors.name ? "true" : "false"}
            />
            {errors.name?.type === "required" && (
              <p className="error-message" role="alert">
                {t("name_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled
              color="var(--white)"
              weight="lighter"
              family="var(--medium)"
            >
              {t("email")}
            </LabelStyled>
            <input
              className="input-field"
              placeholder={t("emailPlaceholder")}
              type="email"
              {...register("email", { required: true })}
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email && (
              <p className="error-message" role="alert">
                {t("email_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled color="var(--white)" weight="lighter">
              {t("phone")}
            </LabelStyled>
            <input
              className="input-field"
              name="phone"
              placeholder={t("phonePlaceholder")}
              type="text"
              value={phone}
              {...register("phone", { required: true })}
              aria-invalid={errors.phone ? "true" : "false"}
              onChange={handlePhoneChange}
            />
            {errors.phone && (
              <p className="error-message" role="alert">
                {t("phone_error")}
              </p>
            )}
          </Box>
          <Box className="field-box">
            <LabelStyled
              color="var(--white)"
              weight="lighter"
              family="var(--medium)"
            >
              {t("message")}
            </LabelStyled>
            <textarea
              className="input-field"
              placeholder={t("messagePlaceholder")}
              cols="30"
              rows="5"
              {...register("message", { required: true })}
              aria-invalid={errors.message ? "true" : "false"}
            />
            {errors.message && (
              <p className="error-message" role="alert">
                {t("message_error")}
              </p>
            )}
          </Box>
          <Box className="d-flex justify-content-end my-3 mx-2 contact-btn">
            {isLoading ? (
              <p className="loading-btn">
                <span className="spinner"></span>
              </p>
            ) : null}
            <ContactButton
              background="var(--orangeGradient)"
              type="submit"
              label={t("send")}
              white
              clName="contactSendBtn"
            />
          </Box>
        </form>
        <ToastContainer />
      </ContactFormWrapper>
    </>
  );
};

export default ContactForm;
