import React from "react";
import { AboutWrapper } from "./Styled";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import {
  Box,
  Heading,
  HeadingStyled,
  LazyImage,
  MainHeading,
  SubHeading,
  TextStyled,
} from "../../styled/Elements";
import { AboutBg, AboutBg2, AboutImg2, batchBg } from "../../styled/AllImages";
import { ContactButton } from "../../styled/CustomElements";

const About = () => {
  const { t } = useTranslation();

  const WhatsApp = () => {
    const phoneNumber = "+966556202503";
    const message = "Hello, Super Active Baby";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <AboutWrapper id="about" className="common-space">
      <Box className="about-content Box-shadow mb-0" style={{ backgroundImage: `url(${AboutBg})` }}>
        <Container>
          <Row className="about-rows justify-content-between">
            <Col lg={5} md={12}>
              <MainHeading size="40px" className="orangeColor">
                {t("our_mission")}
              </MainHeading>
              <Heading size="40px" className="tenDlr">
                {t("ten_million_diapers")}
              </Heading>
            </Col>
            <Col lg={5} md={12}>
              <SubHeading weight="bolder" size="30px" className="blueColor">
                {t("About")}
              </SubHeading>
              <MainHeading weight="bolder" size="40px" className="orangeColor">
                {t("super_active_baby")}
              </MainHeading>
              <TextStyled>{t("every_little_moment_matters")}</TextStyled>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="position-relative batchWrapper">
              <Box className="batch" style={{ backgroundImage: `url(${batchBg})` }}>
                <HeadingStyled size='23px' lh='1'>Over</HeadingStyled>
                <MainHeading lh='1'>10M</MainHeading>
                <HeadingStyled size='23px' lh='1'>Diapers</HeadingStyled>
                <HeadingStyled size='23px' lh='1'>SOLD</HeadingStyled>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>


      <Container>
        <Row className="about2-content common-space" style={{ backgroundImage: `url(${AboutBg2})` }}>
          <Col lg={6} md={12}>
            <Box className="about-text-content">
              <Box className="about-text">
                <MainHeading weight="bolder" className="about-titleHeading l-height" color="var(--white)" size="32px">
                  {t("experience")}
                </MainHeading>
                <MainHeading
                  className="about-mainHeading l-height lightBlue_gradient"
                  size="36px"
                >
                  {t("ultimate_comfort")}
                </MainHeading>
                <MainHeading
                  className="about-andHeading l-height"
                  size="25px"
                >
                  {t("AND")}
                </MainHeading>
                <MainHeading
                  className="about-subheading l-height lightBlue_gradient"
                  size="42px"
                >
                  {t("protection")}
                </MainHeading>
                <MainHeading
                  className="about-textHading l-height lightBlue_gradient"
                  size="24px"
                >
                  {t("for_little_one")}
                </MainHeading>
              </Box>
              <Row className="mt-4 about-contact-btn">
                <ContactButton
                  white
                  label={t("contact_us_now")}
                  onClick={WhatsApp}
                  background="var(--orangeGradient)"
                  clName="protBtn"
                />
              </Row>
            </Box>
          </Col>
          <Col lg={6} md={12}>
            <Box className="about-2-img">
              <LazyImage src={AboutImg2} />
            </Box>
          </Col>
        </Row>
      </Container>
    </AboutWrapper>
  );
};

export default About;
